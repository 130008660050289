import { toast, Bounce } from "react-toastify";

export function toastError(text, timer=1000) {
    toast.error(text, {
        position: "top-right",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce
    });    
}

export function toastSuccess(text, timer=1000) {
    toast.success(text, {
        position: "top-right",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce
    });    
}

export function toastInfo(text, timer=1000) {
    toast.info(text, {
        position: "top-right",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce
    });    
}

export function dateNow() {
    let dia = new Date().toLocaleString("pr-BR", {day: "2-digit"});
    let mes = new Date().toLocaleString("pr-BR", {month: "2-digit"});
    let ano = new Date().toLocaleString("pr-BR", {year: "numeric"});
    return ano + "-" + mes + "-" + dia; //YYYY-MM-DD
}

export function yearNow() {
    let ano = new Date().toLocaleString("pr-BR", {year: "numeric"});
    return parseInt(ano);
}

export function firstDayOfMonth() {
    let data = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let dia = data.toLocaleString("pr-BR", {day: "2-digit"});
    let mes = data.toLocaleString("pr-BR", {month: "2-digit"});
    let ano = data.toLocaleString("pr-BR", {year: "numeric"});    
    return ano + "-" + mes + "-" + dia;
}

export function formataValor(valor, casas_decimais=2) {
    return valor.toLocaleString('pt-br', {minimumFractionDigits: casas_decimais})
}

export function hostNameDSI() {
    const hostname = window?.location?.hostname.toLowerCase();

    return hostname && hostname.includes("dsi.net");
}

export function codLocalHostName() {
    if (hostNameDSI()) 
        return "2";
    else 
        return "1,2,3";
}