import { useState, useEffect, useContext } from "react";
import "./style.css";

import { AuthContext } from "../../context/Auth";
import MenuSideBar from "../../components/MenuSideBar";
import PanelCollapse from "../../components/PanelCollapse";
import { 
    yearNow,
    formataValor,
    toastSuccess,
    codLocalHostName
} from "../../library";
import api, {MENSAGEM_ERRO_HTTP} from "../../api";
import { Oval } from  "react-loader-spinner";
import TotalRegistro from "../../components/TotalRegistro";
import { BiSolidPrinter } from "react-icons/bi";
import { IoBarcode } from "react-icons/io5";
import { SiPix } from "react-icons/si";
import { copyToClipboard } from "../../components/Header";

const BloquetoList = (status, data) => {
    // {item.status === "P" && <span className="bloqueto-item-status-pago">Pago</span>}
    // {item.status === "A" && <span className="bloqueto-item-status-pendente">Pendente</span>}
    // {item.status === "V" && <span className="bloqueto-item-status-vencido">Vencido</span>}

    let vencido = data?.filter(bloqueto => {return bloqueto.status === "V"});
    let aberto = data?.filter(bloqueto => {return bloqueto.status === "A"});
    let pago = data?.filter(bloqueto => {return bloqueto.status === "P"});

    if (status === "T")
        return (
            <>
                {vencido.length ? BloquetoTitle("Vencido", vencido.length, "VENCIDO") : null}
                {vencido?.map(bloqueto => BloquetoItem(bloqueto))}

                {aberto.length ? BloquetoTitle("Em aberto", aberto.length, "ABERTO") : null}
                {aberto?.map(bloqueto => BloquetoItem(bloqueto))}

                {pago.length ? BloquetoTitle("Pago", pago.length, "PAGO") : null}
                {pago?.map(bloqueto => BloquetoItem(bloqueto))}
            </>
        )
    else {
        return (
            <>
                {data?.map(bloqueto => BloquetoItem(bloqueto))}
            </>
        )
    }
}

const BloquetoTitle = (title, length, key) => {
    return (
        <div className="bloqueto-title" key={key}>
            <span>{title} ({length})</span>
        </div>
    )
}

const BloquetoItem = (item) => {
    return (
        <div key={item.id} className="bloqueto-item">
            <div className="bloqueto-details">
                <div className="bloqueto-detail">
                    <span className="bloqueto-detail-title">Vencimento</span>
                    <span className="bloqueto-detail-value">{item.dataVencimento}</span>

                    {item.status === "P" && <span className="bloqueto-item-status-pago">Pago</span>}
                    {item.status === "A" && <span className="bloqueto-item-status-pendente">Pendente</span>}
                    {item.status === "V" && <span className="bloqueto-item-status-vencido">Vencido</span>}
                </div>

                <div className="bloqueto-detail">
                    <span className="bloqueto-detail-title">Valor</span>
                    <span className="bloqueto-detail-value">R$ {formataValor(item.valor)}</span>
                </div>   

                <div className="bloqueto-detail">
                    <span className="bloqueto-detail-title">Nota Fiscal</span>
                    <span className="bloqueto-detail-value">{item.numeroDocumento}</span>
                </div>       
            </div>

            {
                item.linhaDigitavel
                ?
                <div className="bloqueto-options">
                    {item?.linkQRCode
                    ? <span className="bloqueto-btn-options" title="Copia o código PIX para área de transferência" onClick={() => {copyToClipboard(item.linkQRCode); toastSuccess("PIX copiado com sucesso", 2750); }}><SiPix size={22} /></span>
                    : null
                    }

                    <span className="bloqueto-btn-options" title="Copia o código de barras para área de transferência" onClick={() => {copyToClipboard(item.linhaDigitavel); toastSuccess("Código de barras copiado com sucesso", 2750); }}><IoBarcode size={26} /></span>
                    <span className="bloqueto-btn-options" title="Visualiza" onClick={() => window.open(item.linkBloqueto)}><BiSolidPrinter size={24} /></span>
                </div>            
                : null
            }
        </div>
    );
};

const Bloqueto = () => {
    const { setTitle } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ano, setAno] = useState(yearNow());
    const [anoList, setAnoList] = useState([]);
    const [status, setStatus] = useState("A");

    useEffect(() => {
        setTitle("Bloquetos");

        if (anoList.length == 0) {
            let _temp = [ano + 1];
            for (let i = 0; i <= 5; i++)
                _temp.push(ano - i);
            
            setAnoList(_temp);
        }
    }, []);

    useEffect(() => {
        handleFiltra();
    }, [ano, status])

    const handleFiltra = async () => {
        setLoading(true);
        setData([]);

        try {
            let response = await (await api.get(
                `/bloquetoLista?ano=${ano}&status=${status}&codLocal=${codLocalHostName()}`
            )).data;

            let list = [];
            let vencido = [];
            let aberto = [];
            let pago = []; 

            // Bloquetos vencidos
            vencido = response.list?.filter(bloqueto => {return bloqueto.status === "V"});
            // Ordem asc
            vencido.sort(function(a, b) {
                var x = a.dataVencimentoOrdenacao;
                var y = b.dataVencimentoOrdenacao;
                return x < y ? -1 : x > y ? 1 : 0;
            });

            // Bloquetos à vencer = em aberto
            aberto = response.list?.filter(bloqueto => {return bloqueto.status === "A"});
            // Ordem asc
            aberto.sort(function(a, b) {
                var x = a.dataVencimentoOrdenacao;
                var y = b.dataVencimentoOrdenacao;
                return x < y ? -1 : x > y ? 1 : 0;
            });
                
            // Bloquetos pagos
            pago = response.list?.filter(bloqueto => {return bloqueto.status === "P"});
            // Ordem desc já vem do backend a ordenacao desc           
            
            if (status === "T") {
                list = [...list, ...vencido];
                list = [...list, ...aberto];
                list = [...list, ...pago];
            } else if (status === "V") {
                list = [...list, ...vencido];
            } else if (status === "A") {
                list = [...list, ...aberto];
            } else if (status === "P") {
                list = [...list, ...pago];
            }

            setData(list);
        } catch {
            MENSAGEM_ERRO_HTTP();
        }

        setLoading(false);
    };

    return (
        <>
            <MenuSideBar>
                <div className="bloqueto-container">
                    <PanelCollapse>
                        <div className="bloqueto-filtros">
                            <div className="bloqueto-filtro">
                                <span className="bloqueto-filtro-titulo">Ano</span>

                                <select 
                                    className="bloqueto-filtro-input"
                                    value={ano}  
                                    onChange={(el) => setAno(el.target.value)}
                                >
                                    {anoList?.map((ano, key) => {
                                        return <option key={key} value={ano}>{ano}</option>
                                    })}
                                </select>
                            </div>

                            <div className="bloqueto-filtro">
                                <span className="bloqueto-filtro-titulo">Status</span>

                                <select 
                                    className="bloqueto-filtro-input"
                                    value={status}  
                                    onChange={(el) => setStatus(el.target.value)}
                                >
                                    <option value="T">Todos</option>
                                    <option value="A">Em aberto</option>
                                    <option value="V">Vencido</option>
                                    <option value="P">Pago</option>
                                </select>
                            </div>                            

                            <Oval
                                height={32}
                                width={32}
                                color="#b72126"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={loading}
                                ariaLabel="oval-loading"
                                secondaryColor="#b72126"
                                strokeWidth={2}
                                strokeWidthSecondary={4}
                            />
                        </div>
                    </PanelCollapse>

                    <TotalRegistro data={data} />

                    <div className="bloqueto-main">
                        {! loading && Object.keys(data).length ? BloquetoList(status, data) : null}

                        {/* {data?.map(bloqueto => BloquetoItem(bloqueto))} */}

                        {!loading && Object.keys(data).length == 0 && <span className="bloqueto-sem-registro">Nenhum bloqueto encontrado</span>}
                    </div>
                </div>  
            </MenuSideBar>
        </>
    );
};

export default Bloqueto;