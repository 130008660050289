import React, { useState, useContext } from "react";
import "./style.css";

import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/Auth";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { BiSolidBarcode, BiSolidWrench } from "react-icons/bi";
import { BsPersonFill } from "react-icons/bs";
import { hostNameDSI } from "../../library";


import Header from "../Header";

const MenuSideBar = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const userAbrev = localStorage.getItem("userAbrev");
    const { handleLogout } = useContext(AuthContext);

    const menuItemStyles = {
        button: ({ level, active, disabled }) => {
            if (level === 0) {
                return {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: disabled ? "#eee" : "black",
                    backgroundColor: active ? "rgb(192, 192, 192, 0.5); !important" : "white",
                    "&:hover": {
                        display: "flex",
                        backgroundColor: "rgb(192, 192, 192, 0.6); !important",
                    },
                };
            }
        },
        icon: ({active}) => {
            return {
                color: active ? "#b72126" : "silver"
            }
        },
        label: ({active}) => {
            return {
                fontSize: "14px",
                fontWeight: active ? "bold" : "300"
            }
        }
    };

    const size = 24;

    return (
        <div style={{ display: 'flex', height: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                <Sidebar 
                    collapsed={collapsed}
                    toggled={toggled}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={setBroken}
                    breakPoint="md"
                    backgroundColor="white"
                    width="230px"
                >
                    <div className="menu-header">
                        <div className="menu-header-user">
                            <BsPersonFill size={24} color="slategray" />
                            <span className="menu-header-user-name">{userAbrev}</span>
                            <span className="menu-header-logout-exit" onClick={handleLogout}>SAIR</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <Menu menuItemStyles={menuItemStyles}>
                            <MenuItem active={window.location.pathname === "/bloqueto" || window.location.pathname === "/"} component={<Link to="/bloqueto" />} icon={<BiSolidBarcode size={size} />}>Bloqueto</MenuItem>
                            <MenuItem active={window.location.pathname === "/ordem-servico"} component={<Link to="/ordem-servico" />} icon={<BiSolidWrench size={size} />}>Ordem de Serviço</MenuItem>            
                        </Menu>
                    </div>  

                <div className="menu-bottom">
                    <img alt="logo" src={hostNameDSI() ? require("../../assets/img/logo_dsi.png") : require("../../assets/img/logo_defferrari.png")} style={{height: 75, objectFit: "cover"}} />
                </div>                                                                               
                </Sidebar>                                              
            </div>  

            <main className="menu-container">
                <Header  menuToggled={() => setToggled(true)} menuBroken={broken} />
                {children}
            </main>                                  
        </div>
    );
};

export default MenuSideBar;